.fullHeight {
    height: 1528px;
    background: white;
}

.cardHeight {
    height: 764px;
}

.bothPrintableArea {
    display: none;
}

@media print {
    .notPrintableArea {
        display: none;
    }

    .bothPrintableArea {
        display: block;
    }

}

.t {
    transform-origin: bottom left;
    z-index: 2;
    position: absolute;
    white-space: pre;
    overflow: visible;
    line-height: 1.5;
}
.text-container {
    white-space: pre;
}
@supports (-webkit-touch-callout: none) {
    .text-container {
        white-space: normal;
    }
}

#t1_1{left:527.3px;bottom:846.1px;}
#t2_1{left:606px;bottom:847px;letter-spacing:0.12px;}
#t3_1{left:628px;bottom:847px;letter-spacing:0.11px;word-spacing:-0.01px;}
#t4_1{left:473px;bottom:825px;letter-spacing:0.13px;word-spacing:0.01px;}
#t5_1{left:487px;bottom:792px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t6_1{left:502px;bottom:753px;letter-spacing:-0.09px;word-spacing:-0.03px;}
#t7_1{left:448px;bottom:715px;letter-spacing:-0.09px;word-spacing:0.02px;}
#t8_1{left:571px;bottom:678px;letter-spacing:-0.07px;}
#t9_1{left:110px;bottom:612px;letter-spacing:0.13px;word-spacing:-0.02px;}
#ta_1{left:110px;bottom:587px;letter-spacing:0.09px;word-spacing:0.03px;}
#tb_1{left:846px;bottom:587px;letter-spacing:0.12px;word-spacing:0.03px;}
#tc_1{left:110px;bottom:558px;letter-spacing:0.13px;word-spacing:0.12px;}
#td_1{left:234px;bottom:558px;letter-spacing:0.12px;word-spacing:0.03px;}
#te_1{left:176px;bottom:500px;letter-spacing:0.14px;word-spacing:0.01px;}
#tf_1{left:121px;bottom:472px;letter-spacing:0.15px;word-spacing:0.02px;}
#tg_1{left:121px;bottom:443px;letter-spacing:0.14px;word-spacing:0.01px;}
#th_1{left:121px;bottom:393px;letter-spacing:0.14px;word-spacing:-0.04px;}
#ti_1{left:121px;bottom:368px;letter-spacing:0.15px;word-spacing:-0.06px;}
#tj_1{left:167px;bottom:343px;letter-spacing:0.03px;}
#tk_1{left:234px;bottom:343px;}
#tl_1{left:172px;bottom:321px;letter-spacing:0.15px;}
#tm_1{left:237px;bottom:321px;}
#tn_1{left:172px;bottom:299px;letter-spacing:0.21px;word-spacing:9.1px;}
#to_1{left:173px;bottom:277px;letter-spacing:0.13px;word-spacing:1.16px;}
#tp_1{left:110px;bottom:252px;letter-spacing:0.12px;}
#tq_1{left:110px;bottom:209px;letter-spacing:0.11px;word-spacing:0.05px;}
#tr_1{left:855.4px;bottom:187.7px;letter-spacing:-0.12px;word-spacing:-0.02px;}
#ts_1{left:839px;bottom:166px;letter-spacing:0.09px;word-spacing:0.03px;}
#tt_1{left:855px;bottom:144px;letter-spacing:0.11px;word-spacing:0.03px;}
#tu_1{left:833px;bottom:100px;letter-spacing:0.29px;word-spacing:-0.01px;}
#tv_1{left:176px;bottom:57px;letter-spacing:0.09px;word-spacing:-0.02px;}
#tw_1{left:236.1px;bottom:56.5px;letter-spacing:-0.14px;word-spacing:-0.02px;}
#tx_1{left:555.9px;bottom:56.5px;letter-spacing:-0.14px;word-spacing:-0.03px;}

.s1{font-size:26px;font-family:Preeti_1o;color:#000;-webkit-text-stroke:0.7px #000;text-stroke:0.7px #000;}
.s2{font-size:21px;font-family:Preeti_1o;color:#000;}
.s3{font-size:31px;font-family:Preeti_1o;color:#000;-webkit-text-stroke:0.9px #000;text-stroke:0.9px #000;}
.s4{font-size:37px;font-family:Preeti_1o;color:#000;-webkit-text-stroke:1px #000;text-stroke:1px #000;}
.s5{font-size:24px;font-family:Preeti_1o;color:#000;-webkit-text-stroke:0.7px #000;text-stroke:0.7px #000;}
.s6{font-size:24px;font-family:Preeti_1o;color:#000;}
.s7{font-size:21px;font-family:Preeti_1o;color:#000;-webkit-text-stroke:0.6px #000;text-stroke:0.6px #000;}
.s8{font-size:23px;font-family:Preeti_1o;color:#000;-webkit-text-stroke:0.6px #000;text-stroke:0.6px #000;}
.s9{font-size:23px;font-family:Preeti_1o;color:#000;}
.t.v0_1{transform:scaleX(1.098);}
.t.v1_1{transform:scaleX(1.08);}
.t.m0_1{transform:matrix(0.948,0,-0.32,0.95,0,0);}

@font-face {
    font-family: Preeti_1o;
    /*src: url("fonts/Preeti_1o.woff") format("woff");*/
}