.personal_details span {
  font-size: 33px;
}

.cardHeight {
  height: 764px;
}

.fullHeight {
  height: 1528px;
  background: white;
}

.bothPrintableArea {
  display: none;
}

@media print {
  .notPrintableArea {
    display: none;
  }

  .bothPrintableArea {
    display: block;
  }

}
